import React from 'react';
import { ProductProvider } from './src/context/ProductContext';
import { ContextProvider } from './src/context/ContextData';
import { GeneralPricesProvider } from './src/context/ContextGeneralPrices';

export const wrapRootElement = ({ element }) => (
  <ProductProvider>
    <ContextProvider>
      <GeneralPricesProvider>
      {element}
      </GeneralPricesProvider>
    </ContextProvider>
  </ProductProvider>
);
